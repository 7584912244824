import React from 'react'
import ProjectPreview from './project-preview'

import styles from './projectGrid.module.css'

function ProjectGrid (props) {
  console.log(props)
  return (
    <ul className={styles.gridContainer}>
        {props.nodes &&
          props.nodes.map(node => (
          <li key={node.id}>
            <ProjectPreview {...node} />
          </li>
        ))}
    </ul>
  )
}
export default ProjectGrid
