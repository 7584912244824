import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectGrid from '../components/projectGrid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from '../lib/helpers'
import Hero from '../components/subPageHero'

import heroBackground from "../assets/images/hero-background.jpg"

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query WorkPageQuery {
    projects: allSanityProject(
      limit: 50
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          publishedAt

          mainImage {
            asset {
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid_withWebp
              },
              fixed(width: 1600) {
                ...GatsbySanityImageFixed_withWebp
              }
            }
            alt
          }
          thumbImage {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid_withWebp
              },
              fixed(width: 400) {
                ...GatsbySanityImageFixed_withWebp
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const WorkPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const projectNodes =
    data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <SEO title='Work' />
      <Container>
        <Hero heroBackground={{heroBackground}} heroText={'My Work'}  />

        {projectNodes && projectNodes.length > 0 && <ProjectGrid nodes={projectNodes} />}
      </Container>
    </Layout>
  )
}

export default WorkPage
